/* eslint camelcase: off */
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';
import { format } from 'date-fns';
import { isNil } from 'lodash-es';
import Button from '../../components/ui/Button';
import ButtonGroup from '../../components/ui/ButtonGroup';
import Header from '../../components/ui/Header';
import PlanPriceBarplot from '../../components/general/PlanPriceBarplot';
import PlanCostSplit from '../../components/general/PlanCostSplit';
import {
  GetRecommendation_resultSack_actualPlan,
  GetRecommendation_resultSack_recommendationList,
} from '../../apollo/__generated__/GetRecommendation';
import {
  getActualPlanFinalLS,
  getIsActualPlanUserSelectedLS,
  getSelectedRecommendedPlan,
  getTotalResultsMeetingFilters,
  getHasMemberId,
  getSelectedRecommendedPlanIndex,
  getMedianPlanFinalCostLS,
} from '../../utils/localStorage/localStorageFunctions';
import { getActiveResultFiltersSS } from "../../utils/sessionStorage/sessionStorageFunctions";
import { CONTRACT_TERM, StepUrl, TARIFF_TYPE } from '../../utils/enums';
import DetailsTablePlan from '../../components/general/DetailsTablePlan';
import EnergySavingsListItem from '../../components/general/EnergySavingsListItem';
import RetailerPopup from '../../components/general/RetailerPopup';
import { generateRandomIds, getTariffTypeFilter, nth } from '../../utils/utils';
import {
  BlockRate,
  ExtendedPlanInfoType,
  SolarFit,
} from '../../utils/localStorage/localStorageTypes';
import { FiltersType } from "../../utils/sessionStorage/sessionStorageTypes";
import ThingsToKnowTable from '../../components/general/ThingsToKnowTable';
import { GET_PLAN, SELECTED_RETAILER } from '../../apollo/queries';
import { GetPlan, GetPlanVariables } from '../../apollo/__generated__/GetPlan';
import usePreloadPageData from '../../hooks/usePreloadPageData';
import InfoMessage from '../../components/ui/InfoMessage';
import SEOLayout from '../../components/layouts/SEOLayout';
import { website, referral } from '../../utils/getUrl';
import { Description } from '../../components/general/Description';
// @ts-ignore
import { ReactComponent as Popup } from '../../assets/vectors/popup.svg';
import { SelectedRetailer, SelectedRetailerVariables } from '../../apollo/__generated__/SelectedRetailer';

const style = require('../PlanInfo.module.scss');

const PlanInfo = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { location } = props;
  const randomIds = generateRandomIds(3)

  const [getPlan, { data: getPlanData, loading: getPlanLoading }] =
    useLazyQuery<GetPlan, GetPlanVariables>(GET_PLAN);

  const [actualPlan] = useState<GetRecommendation_resultSack_actualPlan | null>(
    () => getActualPlanFinalLS()
  );

  const currentRetailer = actualPlan?.plan?.retailer;

  const [isActualPlanUserSelected] = useState<boolean | null>(() =>
    getIsActualPlanUserSelectedLS()
  );

  const [recommendedPlan] =
    useState<GetRecommendation_resultSack_recommendationList | null>(() =>
      getSelectedRecommendedPlan()
    );

  const [recommendedPlanIndex] = useState(() =>
    getSelectedRecommendedPlanIndex()
  );

  const [pageSubHeadingText, setPageSubHeadingText] = useState(
    'This plan is {5}% cheaper than the median offer'
  );

  const [totalPlans, setTotalPlans] = useState<number>();
  const [activeResultFilters] = useState<FiltersType | null>(() =>
    getActiveResultFiltersSS()
  );

  const [actualPlanFinalCost, setActualPlanFinalCost] = useState(0);
  const [recommendedPlanFinalCost, setRecommendedPlanFinalCost] = useState(0);

  const [savings, setSavings] = useState(0);

  const [recommendedPlanLS] = useState(() => getSelectedRecommendedPlan());

  // SelectedRetailer
  const [selectedRetailer] = useMutation<
    SelectedRetailer,
    SelectedRetailerVariables
  >(SELECTED_RETAILER);

  const trackSelectedRetailer = (plan: any) => {
    const memberId = getHasMemberId() || "";

    selectedRetailer({
      variables: {
        memberId,
        selectedPlanId: plan?.plan?.id,
        selectedRetailerId: plan?.plan?.retailer?.id,
        cmVisitor: (window as any)?.Convertmax?.client?.visitor,
        cmSession: (window as any)?.Convertmax?.client?.session_id,
      },
    });
  };

  const calculateSavingAndSetTexts = () => {
    const medianPlanCost = getMedianPlanFinalCostLS();
    const totalResultsMeetingFilters = getTotalResultsMeetingFilters();

    if (!recommendedPlanLS || !medianPlanCost) return;

    setTotalPlans(totalResultsMeetingFilters ?? 0);

    // @ts-ignore
    const actualPlanCost = actualPlan?.finalCost ?? 0;
    // @ts-ignore
    const recommendedPlanCost = recommendedPlanLS?.finalCost ?? 0;

    let costToCompareTo = medianPlanCost;
    if (isActualPlanUserSelected) {
      costToCompareTo = actualPlanCost;
    }

    setActualPlanFinalCost(costToCompareTo);
    setRecommendedPlanFinalCost(recommendedPlanCost);

    const absoluteSavings = costToCompareTo - recommendedPlanCost;

    const relativeSavings = Math.round(
      (1 - recommendedPlanCost / costToCompareTo) * 100
    );
    setSavings(absoluteSavings);

    // Set texts

    if (isActualPlanUserSelected) {
      if (relativeSavings > 0) {
        setPageSubHeadingText(
          `This plan is ${relativeSavings}% cheaper than your current plan with ${
            currentRetailer?.orgName ?? 'current retailer'
          }`
        );
      } else {
        setPageSubHeadingText(
          `This plan is ${Math.abs(
            relativeSavings
          )}% more expensive than your current plan with ${
            currentRetailer?.orgName ?? 'current retailer'
          }`
        );
      }
      return;
    }
    if (relativeSavings > 0) {
      setPageSubHeadingText(
        `This plan is ${relativeSavings}% cheaper than the median offer`
      );
    } else {
      setPageSubHeadingText(
        `This plan is ${Math.abs(
          relativeSavings
        )}% more expensive than the median offer`
      );
    }
  };

  const getExtendedPlanInfo = () => {
    const recommendedPlanId = recommendedPlanLS?.plan?.id;

    if (!recommendedPlanId) return;
    getPlan({ variables: { planId: recommendedPlanId } });
  };

  const [extendedPlanInfo, setExtendedPlanInfo] =
    useState<ExtendedPlanInfoType>({
      dailySupplyCharge: null,
      usageRate: null,
      usageRateMin: null,
      usageRateMax: null,
      blockRates: null,
      controlledLoadDailyCharge1: null,
      controlledLoadDailyCharge2: null,
      controlledLoadRate1: null,
      controlledLoadRate2: null,
      solarFeedIn: [],
      greenPowerCharge: null,
      membershipFee: null,
      discountAmount: null,
      discountPercent: null,
      discountName: null,
      benefitPeriod: null,
      coolingOffDays: null,
      effectiveDate: null,
      isPriceFixed: null,
      hasGreenPowerOption: null,
      meterTypes: null,
      billFrequencies: null,
      distributor: null,
      unconditionalPrice: null,
      unconditionalPricePercentDiff: null,
      conditionalPrice: null,
      conditionalPricePercentDiff: null,
      guAmount: null,
      resOrBus: null,
    });

  useEffect(() => {
    if (!getPlanData || getPlanLoading) return;

    const tax = 1.1;
    const planData = getPlanData.getPlan;

    // Usage rate
    let usageRate = null;
    let usageRateMin = null;
    let usageRateMax = null;
    let blockRates = null;

    if (planData?.srplan) {
      usageRate = ((planData?.srplan?.rate ?? 0) / 100) * tax;
    } else if (planData?.touplan) {
      const touBlocks = planData?.touplan?.touBlocks;
      const touBlockItems = touBlocks?.[0]?.touBlockItems;

      const minUnitPrice = touBlockItems?.reduce(
        (min, touBlockItem) =>
          parseFloat(touBlockItem?.unitPrice ?? '0') < min
            ? parseFloat(touBlockItem?.unitPrice ?? '0')
            : min,
        parseFloat(touBlockItems?.[0]?.unitPrice ?? '0')
      );

      const maxUnitPrice = touBlockItems?.reduce(
        (max, touBlockItem) =>
          parseFloat(touBlockItem?.unitPrice ?? '0') > max
            ? parseFloat(touBlockItem?.unitPrice ?? '0')
            : max,
        parseFloat(touBlockItems?.[0]?.unitPrice ?? '0')
      );

      usageRateMin = ((minUnitPrice ?? 0) / 100) * tax;
      usageRateMax = ((maxUnitPrice ?? 0) / 100) * tax;
    } else if (planData?.blockplan) {
      blockRates = planData?.blockplan?.blockRateItems as BlockRate[];
    }

    // Controlled load daily charge and rates
    let controlledLoadDailyCharge1 = null;
    let controlledLoadDailyCharge2 = null;
    let controlledLoadRate1 = null;
    let controlledLoadRate2 = null;

    if (planData?.controlledloads && planData.controlledloads.length) {
      const cl1 = planData.controlledloads?.[0];
      const cl2 = planData.controlledloads?.[1];

      if (cl1) {
        controlledLoadDailyCharge1 = ((cl1?.dailyCharge ?? 0) / 100) * tax;
        controlledLoadRate1 = ((cl1?.rate ?? 0) / 100) * tax;
      }
      if (cl2) {
        controlledLoadRate2 = ((cl2?.rate ?? 0) / 100) * tax;
        controlledLoadDailyCharge2 = ((cl2?.dailyCharge ?? 0) / 100) * tax;
      }
    }

    // Solar feed in tariff
    let solarFeedIn = null;

    if (planData?.solarfits) {
      solarFeedIn = (planData.solarfits as SolarFit[]).filter((solarfit) => solarfit?.solarfitType === 'R');
    }

    // Green power charge and Grenn power option
    let greenPowerCharge = null;
    let hasGreenPowerOption = 'No';

    if (planData?.greencharges && planData?.greencharges.length) {
      const greenCharge100 = planData?.greencharges?.find(
        (greenCharge) =>
          parseInt(greenCharge?.greenChargeOptions?.[0]?.percentGreenPower, 10) === 100
      );

      if (greenCharge100) {
        hasGreenPowerOption = 'Yes';
        greenPowerCharge =
          greenCharge100?.greenChargeOptions?.[0]?.chargeAmount ?? 0;
      }
    }

    // Membership fee
    let membershipFee = null;

    if (planData?.fees && planData.fees.length) {
      const MBSF = planData.fees.find((fee) => fee?.feeType === 'MBSF');
      if (MBSF) {
        membershipFee = MBSF?.amount ?? 0;
      }
    }

    // Discount
    let discountAmount = null;
    let discountPercent = null;
    let discountName = null;

    if (planData?.discounts && planData.discounts.length) {
      const discount = planData.discounts[0];

      if (discount?.amount) {
        discountAmount = discount?.amount ?? 0;
      } else if (discount?.percent) {
        discountPercent = discount.percent ?? 0;
      }
      if (discount?.name) {
        discountName = discount?.name;
      }
    }

    let effectiveDate = null;

    // Plan valid from a.k.a effective date
    if (planData?.effectiveDate) {
      effectiveDate = format(new Date(planData?.effectiveDate), 'dd MMMM yyyy');
    }

    // Pricing fixed
    let isPriceFixed = null;

    if (planData?.isFixed !== null) {
      isPriceFixed = planData?.isFixed ? 'Yes' : 'No';
    }

    // Meter types
    let meterTypes = null;
    if (planData?.meterTypes && planData.meterTypes.length) {
      meterTypes = planData.meterTypes;
    }

    // Bill frequencies
    let billFrequencies = null;
    if (planData?.billFrequencies && planData.billFrequencies.length) {
      billFrequencies = planData.billFrequencies;
    }

    const extendedPlanInfoTemp = {
      dailySupplyCharge: ((planData?.dailySupplyCharge ?? 0) / 100) * tax,
      usageRate,
      usageRateMin,
      usageRateMax,
      blockRates,
      controlledLoadDailyCharge1,
      controlledLoadDailyCharge2,
      controlledLoadRate1,
      controlledLoadRate2,
      solarFeedIn,
      greenPowerCharge,
      membershipFee,
      discountAmount,
      discountPercent,
      discountName,
      benefitPeriod: planData?.benefitPeriod ?? null,
      coolingOffDays: planData?.coolingOffDays ?? null,
      effectiveDate,
      isPriceFixed,
      hasGreenPowerOption,
      meterTypes,
      billFrequencies,
      distributor: planData?.distributor ?? null,
      unconditionalPrice: planData?.unconditionalPrice ?? null,
      unconditionalPricePercentDiff:
        planData?.unconditionalPricePercentDiff ?? null,
      conditionalPrice: planData?.conditionalPrice ?? null,
      conditionalPricePercentDiff:
        planData?.conditionalPricePercentDiff ?? null,
      guAmount: planData?.guAmount ?? null,
      resOrBus: planData?.resOrBus ?? null,
    };

    setExtendedPlanInfo(extendedPlanInfoTemp);
  }, [getPlanData, getPlanLoading]);

  const [showPopup, setShowPopup] = useState(false);

  const handleBack = () => {
    window.history.back();
  };

  usePreloadPageData(StepUrl.URL_ALMOST_THERE, StepUrl.URL_RESULTS);

  const isUnconditionalRegion1 = [
    'Ausgrid',
    'Endeavour',
    'Essential Energy',
    'Energex',
    'SA Power Networks',
  ].includes(extendedPlanInfo.distributor?.name ?? '');

  const isUnconditionalRegion2 = [
    'Ausnet Services',
    'CitiPower',
    'Jemena',
    'PowerCor',
    'United Energy',
  ].includes(extendedPlanInfo.distributor?.name ?? '');

  const showUnconditionalPrice =
    isUnconditionalRegion1 || isUnconditionalRegion2;

  const isBusiness = extendedPlanInfo.resOrBus === 'B';

  const retailerWebsiteUrl = recommendedPlan?.plan?.retailer?.websiteUrl ?? '';
  const retailerReferralUrl =
    recommendedPlan?.plan?.retailer?.referralUrl ?? '';

  const Url = () => {
    if (!retailerReferralUrl) {
      return website(retailerWebsiteUrl);
    } else {
      return referral(retailerReferralUrl);
    }
  };

  const handleChoosePlan = useCallback(() => {
    if (!recommendedPlan) return;

    setShowPopup(true);
    trackSelectedRetailer(recommendedPlan);
  }, [recommendedPlan?.plan])

  /**
   * Entry point
   */
  useEffect(() => {
    calculateSavingAndSetTexts();
    getExtendedPlanInfo();

    const retailerPopup = localStorage.getItem('retailer_popup');

    if (retailerPopup === '1') {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, []);

  return (
    <SEOLayout>
      <div className="container-fluid" style={{ maxWidth: '1500px' }}>
        <RetailerPopup
          retailerName={recommendedPlan?.plan?.retailer?.orgName ?? ''}
          planName={recommendedPlan?.plan?.name ?? ''}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          planCode={recommendedPlan?.plan?.planId ?? ''}
          tariffType={TARIFF_TYPE?.[recommendedPlan?.plan?.tariffType ?? 'OTH']}
          contractTermType={
            CONTRACT_TERM?.[recommendedPlan?.plan?.contractTermType ?? 'O']
          }
        />
        <Header />
        <section className={style.head}>
          <div>
            <Media lessThan="desktop">
              <h3 className="my-2">
                This plan ranks{' '}
                {`${recommendedPlanIndex}${nth(recommendedPlanIndex)}`} out of{' '}
                {totalPlans} plans that meet your selections.
              </h3>
            </Media>
            <Media greaterThanOrEqual="desktop">
              <h1 className="my-2">
                This plan ranks{' '}
                {`${recommendedPlanIndex}${nth(recommendedPlanIndex)}`} out of{' '}
                {totalPlans} plans that meet your selections.
              </h1>
            </Media>
            <p className="sub-heading">{pageSubHeadingText}</p>
            <Media greaterThanOrEqual="desktop">
              <ButtonGroup style={{ marginTop: '16px' }} wrapping="direct">
                <a
                  href={Url()}
                  target="_blank"
                  rel="noreferrer"
                  className="a-signup"
                  data-plan-name={recommendedPlan?.plan?.name ?? ''}
                  data-plan-id={recommendedPlan?.plan?.planId ?? ''}
                  data-plan-retailer={
                    recommendedPlan?.plan?.retailer?.orgName ?? ''
                  }
                  data-plan-en={recommendedPlan?.plan?.retailer?.referralUrl ? 'true':'false'}
                >
                  <Button
                    type="button"
                    id={randomIds[0]}
                    className={`${style.buttonChange} change-plan-btn`}
                    onClick={handleChoosePlan}
                  >
                    <span className="span-signup" style={{ marginRight: '5px' }}>Go to Sign Up</span>
                    <Popup width={16} height={16} />
                  </Button>
                </a>
                <Button type="button" variant="tertiary" onClick={handleBack}>
                  Back to results
                </Button>
              </ButtonGroup>
            </Media>
          </div>
          <div className={style.barPlotWrapper}>
            <PlanPriceBarplot
              thisPlanCost={Math.round(recommendedPlanFinalCost / 12)}
              yourPlanCost={Math.round(actualPlanFinalCost / 12)}
              className={style.barPlot}
              defaultBarHeight={150}
              isActualPlanUserSelected={isActualPlanUserSelected}
            />
            <Media lessThan="desktop">
              <ButtonGroup style={{ marginTop: '16px' }} wrapping="direct">
                <a
                  href={Url()}
                  target="_blank"
                  rel="noreferrer"
                  className="a-signup"
                  data-plan-name={recommendedPlan?.plan?.name ?? ''}
                  data-plan-id={recommendedPlan?.plan?.planId ?? ''}
                  data-plan-retailer={
                    recommendedPlan?.plan?.retailer?.orgName ?? ''
                  }
                  data-plan-en={recommendedPlan?.plan?.retailer?.referralUrl ? 'true':'false'}
                >
                  <Button
                    type="button"
                    id={randomIds[1]}
                    className={`${style.buttonChange} change-plan-btn`}
                    onClick={handleChoosePlan}
                  >
                    <span className="span-signup" style={{ marginRight: '5px' }}>Go to Sign Up</span>
                    <Popup width={16} height={16} />
                  </Button>
                </a>
                <Button type="button" variant="tertiary" onClick={handleBack}>
                  Back to results
                </Button>
              </ButtonGroup>
            </Media>
          </div>
        </section>
        <section className={style.mainSection}>
          <main className={style.energyPlans}>
            <EnergySavingsListItem
              key={recommendedPlan?.plan?.planId}
              savingsPerYear={Math.round(savings)}
              savingsPerMonth={Math.round(savings / 12)}
              planName={recommendedPlan?.plan?.name ?? ''}
              planCode={recommendedPlan?.plan?.planId ?? ''}
              retailerName={recommendedPlan?.plan?.retailer?.orgName ?? ''}
              retailerEnhanced={recommendedPlan?.plan?.retailer?.referralUrl ? 'true' : 'false'}
              variant="full"
              onClickChoosePlan={handleChoosePlan}
              Url={Url}
              green={getPlanData?.getPlan?.greencharges}
              incentives={getPlanData?.getPlan?.incentives}
              restrictions={getPlanData?.getPlan?.restrictions}
              card={getPlanData?.getPlan?.paymentOptions}
            />
            <Media lessThan="tablet">
              {(mediaClassNames: any) => (
                <h4 style={{ marginTop: '24px' }} className={mediaClassNames}>
                  Cost split
                </h4>
              )}
            </Media>
            <Media greaterThanOrEqual="tablet">
              {(mediaClassNames: any) => (
                <h2 style={{ marginTop: '50px' }} className={mediaClassNames}>
                  Cost split
                </h2>
              )}
            </Media>
            <PlanCostSplit
              actualPlan={actualPlan}
              recommendedPlan={recommendedPlan}
              activeResultFilters={activeResultFilters}
              currentRetailer={currentRetailer}
              isActualPlanUserSelected={isActualPlanUserSelected}
            />
            <ButtonGroup className={style.buttonGroupBottom} wrapping="direct">
              <a
                href={Url()}
                target="_blank"
                rel="noreferrer"
                className="a-signup"
                data-plan-name={recommendedPlan?.plan?.name ?? ''}
                data-plan-id={recommendedPlan?.plan?.planId ?? ''}
                data-plan-retailer={
                  recommendedPlan?.plan?.retailer?.orgName ?? ''
                }
                data-plan-en={recommendedPlan?.plan?.retailer?.referralUrl ? 'true':'false'}
              >
                <Button
                  type="button"
                  id={randomIds[2]}
                  className={`${style.buttonChange} change-plan-btn`}
                  onClick={handleChoosePlan}
                >
                  <span className="span-signup" style={{ marginRight: '5px' }}>Go to Sign Up</span>
                  <Popup width={16} height={16} />
                </Button>
              </a>
              <Button type="button" variant="tertiary" onClick={handleBack}>
                Back to results
              </Button>
            </ButtonGroup>
          </main>
          <aside className={style.sideMenu}>
            <DetailsTablePlan
              contractTerm={
                CONTRACT_TERM?.[recommendedPlan?.plan?.contractTermType ?? 'O']
              }
              tariffType={getTariffTypeFilter(recommendedPlan?.plan)}
              planData={getPlanData?.getPlan || {}}
              extendedPlanInfo={extendedPlanInfo}
            />
            <div className={style.unconditionalPriceSection}>
              {showUnconditionalPrice && (
                <>
                  {!isNil(extendedPlanInfo.unconditionalPricePercentDiff) && (
                    <InfoMessage variant="success">
                      {Math.round(
                        extendedPlanInfo.unconditionalPricePercentDiff
                      )}
                      % less than the{' '}
                      {isUnconditionalRegion1
                        ? 'Reference Price.'
                        : 'Victorian Default Offer.'}
                    </InfoMessage>
                  )}
                  {isUnconditionalRegion1 && !isNil(extendedPlanInfo.guAmount) && (
                    <InfoMessage variant="success" className="mt-2">
                      For an average {isBusiness ? 'business' : 'residential'}{' '}
                      customer using {extendedPlanInfo?.guAmount} kWh/year in
                      the {extendedPlanInfo?.distributor?.name} region. Excludes
                      any feed-in tariff credit and your bill will differ based
                      on your usage.
                    </InfoMessage>
                  )}
                  {isUnconditionalRegion2 &&
                    !isNil(extendedPlanInfo.unconditionalPrice) &&
                    !isNil(extendedPlanInfo.guAmount) && (
                      <InfoMessage variant="success" className="mt-2">
                        Estimated annual cost of $
                        {Math.round(extendedPlanInfo.unconditionalPrice ?? 0)}
                        /year inc. GST for an average{' '}
                        {isBusiness ? 'business' : 'residential'} customer using{' '}
                        {extendedPlanInfo?.guAmount} kWh/year in the{' '}
                        {extendedPlanInfo?.distributor?.name} region. Excludes
                        any feed-in tariff credit and your bill will differ
                        based on your usage.
                      </InfoMessage>
                    )}
                </>
              )}
            </div>
            <ThingsToKnowTable extendedPlanInfo={extendedPlanInfo} />
            <Description
              incentive={getPlanData?.getPlan?.incentives?.[0]?.description || ''}
              restrictions={
                getPlanData?.getPlan?.restrictions?.[0]?.description || ''
              }
              solarFits={extendedPlanInfo.solarFeedIn}
            />
          </aside>
        </section>
      </div>
    </SEOLayout>
  );
};

export default PlanInfo;
