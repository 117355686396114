import React from 'react';
import { SolarFit } from '../../../utils/localStorage/localStorageTypes';

const style = require('./Description.module.scss');

type DescriptionProps = {
  incentive: string;
  restrictions: string;
  [otherProps: string]: any;
  solarFits: SolarFit[] | null;
};

export const Description = (props: DescriptionProps) => {
  const { incentive, restrictions, solarFits } = props;

  return (
    <>
      {(incentive || restrictions || (solarFits && solarFits.length > 0)) && (
        <div className={style.description}>
          <h4>The details</h4>
          {incentive ? (
            <>
              <h5>Incentive</h5>
              <p>{incentive}</p>
            </>
          ) : null}
          {restrictions ? (
            <>
              <h5>Restrictions</h5>
              <p>{restrictions}</p>
            </>
          ) : null}
          {solarFits &&
            solarFits.map((solarFit: SolarFit, index: number) => {
              return (
                <React.Fragment key={`${index}`}>
                  <h5>Solar feed-in-tariff {index + 1}</h5>
                  <p>{solarFit.description}</p>
                </React.Fragment>
              );
            })}
        </div>
      )}
    </>
  );
};
