import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import { ReactComponent as FileContractIcon } from '../../../assets/vectors/FileContract.svg';
// @ts-ignore
import { ReactComponent as FileRemoveIcon } from '../../../assets/vectors/FileRemoveIcon.svg';
// @ts-ignore
import { ReactComponent as TagIcon } from '../../../assets/vectors/Tag.svg';
import { getRetailerLogoUrl } from '../RetailersLogos';
import { navigateAndSetActiveStep } from '../../../utils/utils';

const style = require('./RetailerPopup.module.scss');

type RetailerPopupProps = {
  className?: string;
  retailerName: string;
  planName: string;
  planCode: string;
  // eslint-disable-next-line react/boolean-prop-naming
  showPopup: boolean;
  setShowPopup: Function;
  [otherProps: string]: any;
  tariffType: string;
  contractTermType: string;
};

const RetailerPopup = (props: RetailerPopupProps) => {
  const {
    className,
    retailerName,
    planName,
    planCode,
    showPopup,
    setShowPopup,
    tariffType,
    contractTermType,
    ...rest
  } = props;

  const retailer = retailerName.toLowerCase().trim().replace(/\s+/g, '-');

  return (
    <div
      {...rest}
      className={classNames(style.retailerPopupWrapper, className)}
      style={{ display: showPopup ? 'flex' : 'none' }}
      onClick={() => {
        setShowPopup(false);
      }}
    >
      <div
        className={style.retailerPopup}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <FileRemoveIcon
          className={style.closeIcon}
          onClick={() => {
            setShowPopup(false);
            navigateAndSetActiveStep(
              // @ts-ignore
              `/plan-info/${retailer}/${planCode}`,
              undefined,
              true
            );
          }}
          width={24}
          height={24}
        />
        <img
          src={getRetailerLogoUrl(retailerName)}
          alt={retailerName}
          width={280}
          height={80}
          className={style.retailerLogo}
        />
        <p className={style.orangeItemHeading}>Retailer name</p>
        <div className={style.orangeItem}>{retailerName}</div>
        <p className={style.orangeItemHeading}>Plan name</p>
        <div className={style.orangeItem}>{planName}</div>
        <div className={style.planDetails}>
          <div>
            <div>EME code</div>
            <div className={style.planCode}>{planCode}</div>
          </div>
          <div className={style.rowIconText}>
            <FileContractIcon width={18} height={18} />
            <div className={style.planDetailsText}>{contractTermType}</div>
          </div>
          <div className={style.rowIconText}>
            <TagIcon width={18} height={18} />
            <div className={style.planDetailsText}>{tariffType}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

RetailerPopup.defaultProps = {
  className: '',
};

export default RetailerPopup;
