import React, { useState } from 'react';
import classNames from 'classnames';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';
import Button from '../../ui/Button';
import { getRetailerLogoUrl } from '../RetailersLogos';
// @ts-ignore
import { ReactComponent as Green } from '../../../assets/vectors/greenActive.svg';
// @ts-ignore
import { ReactComponent as Card } from '../../../assets/vectors/cardActive.svg';
// @ts-ignore
import { ReactComponent as Restrictions } from '../../../assets/vectors/restrictionsActive.svg';
// @ts-ignore
import { ReactComponent as Incentives } from '../../../assets/vectors/incentivesActive.svg';
import ResultsToolTip from '../ResultsToolTip';
// @ts-ignore
import { ReactComponent as Popup } from '../../../assets/vectors/popup.svg';
import { generateRandomIds } from '../../../utils/utils';

const style = require('./EnergySavingsListItem.module.scss');

type EnergySavingsListItemProps = {
  retailerName: string;
  planName: string;
  planCode: string;
  retailerEnhanced: string;
  onClickChoosePlan?: Function;
  [otherProps: string]: any;
};

const EnergySavingsListItem = (props: EnergySavingsListItemProps) => {
  const {
    planName,
    planCode,
    retailerName,
    retailerEnhanced,
    savingsPerYear,
    savingsPerMonth,
    onClickChoosePlan,
    Url,
    green,
    incentives,
    restrictions,
    card,
  } = props;

  const [visibleToolTipRestrictions, setVisibleToolTipRestrictions] =
    useState(false);
  const [visibleToolTipIncentives, setVisibleToolTipIncentives] =
    useState(false);
  const [visibleToolTipCard, setVisibleToolTipCard] = useState(false);
  const [visibleToolTipGreen, setVisibleToolTipGreen] = useState(false);

  const randomIds = generateRandomIds(2);

  return (
    <div className={style.energyPlanListItem}>
      <div className={style.energyPlanListItemRow}>
        <div className={style.nameAndTableColumn}>
          <img
            src={getRetailerLogoUrl(retailerName)}
            alt={retailerName}
            className={style.retailerLogo}
            width={240}
            height={60}
          />
          <div className={style.planNameWrapper}>
            <p className={style.planName}>{planName}</p>
            <p className={style.planCode}>{planCode}</p>
          </div>
          <div className={style.table} role="table">
            <div className={style.tableRow} role="rowgroup">
              <div
                className={classNames(style.toolTip, {
                  [style.active]: card?.length,
                })}
                onMouseEnter={() => {
                  setVisibleToolTipCard(true);
                }}
                onMouseLeave={() => {
                  setVisibleToolTipCard(false);
                }}
                role="row"
                tabIndex={0}
              >
                {card?.length ? (
                  <>
                    {visibleToolTipCard && (
                      <ResultsToolTip text="Credit Card required" role="cell" />
                    )}
                    <Card role="cell" />
                    <span style={{ fontSize: '10px' }} role="cell">Card</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={style.tableRow} role="rowgroup">
              <div
                className={classNames(style.toolTip, {
                  [style.active]: incentives?.length,
                })}
                onMouseEnter={() => {
                  setVisibleToolTipIncentives(true);
                }}
                onMouseLeave={() => {
                  setVisibleToolTipIncentives(false);
                }}
                role="row"
                tabIndex={0}
              >
                {incentives?.length ? (
                  <>
                    {visibleToolTipIncentives && (
                      <ResultsToolTip text="This plan has incentives" role="cell" />
                    )}
                    <Incentives role="cell" />
                    <span style={{ fontSize: '10px' }} role="cell">Incentives</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className={style.table} role="table">
            <div className={style.tableRow} role="rowgroup">
              <div
                className={classNames(style.toolTip, {
                  [style.active]: green?.length,
                })}
                onMouseEnter={() => {
                  setVisibleToolTipGreen(true);
                }}
                onMouseLeave={() => {
                  setVisibleToolTipGreen(false);
                }}
                role="row"
                tabIndex={0}
              >
                {green?.length ? (
                  <>
                    {visibleToolTipGreen && (
                      <ResultsToolTip
                        text="This plan has Green Power options"
                        type="green"
                        role="cell"
                      />
                    )}
                    <Green role="cell" />
                    <span style={{ fontSize: '10px' }} role="cell">Green</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={style.tableRow} role="rowgroup">
              <div
                className={classNames(style.toolTip, {
                  [style.active]: restrictions?.length,
                })}
                onMouseEnter={() => {
                  setVisibleToolTipRestrictions(true);
                }}
                onMouseLeave={() => {
                  setVisibleToolTipRestrictions(false);
                }}
                role="row"
                tabIndex={0}
              >
                {restrictions?.length ? (
                  <>
                    {visibleToolTipRestrictions && (
                      <ResultsToolTip text="This plan has restrictions" role="cell" />
                    )}
                    <Restrictions role="cell" />
                    <span style={{ fontSize: '10px' }} role="cell">Restrictions</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={style.estimatedCost}>
          <p>Estimated Saving with this plan</p>
          <Media lessThan="tablet">
            <h2>${savingsPerYear}</h2>
          </Media>
          <Media greaterThanOrEqual="tablet">
            <h1>${savingsPerYear}</h1>
          </Media>
          <p>(${savingsPerMonth}/month)</p>
          <Media greaterThanOrEqual="tablet">
            <a
              href={Url()}
              target="_blank"
              rel="noreferrer"
              className="a-signup"
              data-plan-id={planCode}
              data-plan-name={planName}
              data-plan-retailer={retailerName}
              data-plan-en={retailerEnhanced}
            >
              <Button
                type="button"
                id={randomIds[0]}
                onClick={onClickChoosePlan}
                className={`${style.buttonPrimary} change-plan-btn`}
              >
                <span className="span-signup" style={{ marginRight: '5px' }}>Go to Sign Up</span>
                <Popup width={16} height={16} />
              </Button>
            </a>
          </Media>
        </div>
        <Media lessThan="tablet">
          <a href={Url()} target="_blank" rel="noreferrer" className="a-signup">
            <Button
              type="button"
              id={randomIds[1]}
              className={`${style.buttonChange} change-plan-btn`}
              onClick={onClickChoosePlan}
            >
              <span className="span-signup" style={{ marginRight: '5px' }}>Go to Sign Up</span>
              <Popup width={16} height={16} />
            </Button>
          </a>
        </Media>
      </div>
    </div>
  );
};

EnergySavingsListItem.defaultProps = {
  onClickChoosePlan: () => {},
};

export default EnergySavingsListItem;

