import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { ExtendedPlanInfoType } from '../../../utils/localStorage/localStorageTypes';
import { BillFrequencyPeriod, MeterType } from '../../../utils/enums';
import { getLocalityLS, getRegionPostalCode } from '../../../utils/localStorage/localStorageFunctions';

const style = require('./DetailsTablePlan.module.scss');

type DetailsTablePlanProps = {
  className?: string;
  contractTerm?: string;
  bpidLink?: string;
  tariffType?: string;
  extendedPlanInfo: ExtendedPlanInfoType;
  [otherProps: string]: any;
};

const DetailsTablePlan = (props: DetailsTablePlanProps) => {
  const {
    className,
    contractTerm,
    onEnergyFactClick,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bpidLink,
    tariffType,
    extendedPlanInfo,
    planData,
    ...rest
  } = props;

  const {
    benefitPeriod,
    coolingOffDays,
    effectiveDate,
    isPriceFixed,
    hasGreenPowerOption,
    meterTypes,
    billFrequencies,
    distributor,
  } = extendedPlanInfo;

  const [localityLS] = useState(() => getLocalityLS());
  const regionPostalCode = useMemo(() => getRegionPostalCode(), []);

  const { isVec, planFactSheet, resOrBus } = planData;

  const textLink = isVec
    ? 'Energy Fact Sheet'
    : 'Basic Plan Information Document' || '';

  const Link = () => {
    if (isVec) {
      if (!planFactSheet) {
        if (resOrBus === 'R') {
          return planData?.retailer?.vecResiEfsUrl;
        } else if (resOrBus === 'B') {
          return planData?.retailer?.vecBusiEfsUrl;
        }
      } else {
        return planFactSheet;
      }
    } else if (!isVec) {
      return `https://www.energymadeeasy.gov.au/plan?id=${planData?.planId}&postcode=${regionPostalCode || localityLS?.postcode?.postcode}`;
    }
  };

  return (
    <div {...rest} className={classNames(style.planDetails, className)}>
      <h4>Plan details</h4>
      <table>
        <tbody>
          {distributor && (
            <tr>
              <td>Distributor</td>
              <td>{distributor.name}</td>
            </tr>
          )}
          {tariffType && (
            <tr>
              <td>Tariff type</td>
              <td>{tariffType}</td>
            </tr>
          )}
          {benefitPeriod && (
            <tr>
              <td>Benefit period</td>
              <td>{benefitPeriod}</td>
            </tr>
          )}
          {contractTerm && (
            <tr>
              <td>Contract term</td>
              <td>{contractTerm}</td>
            </tr>
          )}
          {coolingOffDays !== null && (
            <tr>
              <td>Cooling off period</td>
              <td>{coolingOffDays} days</td>
            </tr>
          )}
          {billFrequencies && (
            <tr>
              <td>Billing period(s)</td>
              <td>
                {billFrequencies.map(
                  (billFrequency, index) =>
                    billFrequency?.period &&
                    billFrequency?.number &&
                    BillFrequencyPeriod?.[billFrequency?.period] && (
                      <React.Fragment key={index}>
                        {billFrequency?.number}{' '}
                        {BillFrequencyPeriod?.[billFrequency?.period]}(s)
                        <br />
                      </React.Fragment>
                    )
                )}
              </td>
            </tr>
          )}
          {effectiveDate && (
            <tr>
              <td>Plan valid from</td>
              <td>{effectiveDate}</td>
            </tr>
          )}
          {isPriceFixed && (
            <tr>
              <td>Pricing fixed</td>
              <td>{isPriceFixed}</td>
            </tr>
          )}
          {hasGreenPowerOption && (
            <tr>
              <td>Green power option</td>
              <td>{hasGreenPowerOption}</td>
            </tr>
          )}
          {meterTypes && (
            <tr>
              <td>Meter type(s)</td>
              <td className={style.whiteSpaceNormal}>
                {meterTypes.map(
                  (meterType, index) =>
                    !!meterType?.meterType &&
                    MeterType?.[meterType?.meterType] && (
                      <React.Fragment key={index}>
                        {MeterType?.[meterType?.meterType]}
                        <br />
                      </React.Fragment>
                    )
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <a href={Link()} target="_blank" rel="noopener noreferrer nofollow">
        {textLink}
      </a>
    </div>
  );
};

DetailsTablePlan.defaultProps = {
  bpidLink: '#',
  className: '',
  contractTerm: '',
  tariffType: '',
};

export default DetailsTablePlan;
